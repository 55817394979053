import { Box, Heading, HStack, Text, Button, Link } from "@chakra-ui/react";
import React, { ReactInstance } from "react";
import {
  PDFDownloadLink,
  Image,
  Link as PDFlink,
  Document,
  Page,
  StyleSheet,
  Text as PDFText,
  View,
} from "@react-pdf/renderer";

import Teddy from "../Teddy";
import { colors } from "../../theme";

interface DonationReceiptProps {
  imagePng: string | null | undefined;
  teddyId: string | undefined;
  onDownloadReceipt: () => void;
  txLink: string | undefined;
  txHash: string | undefined;
  date: string | undefined;
}

interface DonationReceiptMessageProps {
  imagePng: string | null | undefined;
  teddyId: string | undefined;
  txLink: string | undefined;
  txHash: string | undefined;
  date: string | undefined;
}

export const DonationReceiptMessage = ({
  imagePng,
  teddyId,
  txHash,
  txLink,
  date,
}: DonationReceiptMessageProps) => {
  return (
    <div>
      {/** @ts-ignore */}
      <Box p={4} backgroundColor="white">
        <Box
          backgroundColor="brand.500"
          height="64px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Heading
            width="100%"
            as="h3"
            fontSize="3xl"
            textAlign="center"
            color="white"
          >
            Thank You!
          </Heading>
        </Box>
        <HStack alignItems="flex-start" py={8}>
          <Box minWidth="250px">
            {imagePng && (
              <Teddy
                imgPath={imagePng}
                alt="teddy"
                // className={classes.teddyImg}
                // wrapperClassName={classes.teddyWrapper}
              />
            )}
          </Box>
          <Box>
            <Text fontSize="2xl" fontWeight="bold">
              Donation Receipt for your Teddy DAO NFT Purchase
            </Text>
            <Box fontSize="sm">
              <Text>The Teddy DAO Foundation</Text>
              <Text>85 Sherman Turnpike</Text>
              <Text>Redding, Connecticut</Text>
              <Text>United States</Text>
              <Text>06896</Text>
              <Text>
                EIN:
                <Link
                  href="https://apps.irs.gov/pub/epostcard/dl/FinalLetter_92-2229692_THETEDDYDAOFOUNDATION_02102023_00.pdf"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                  textDecoration="underline"
                >
                  92-2229692
                </Link>
              </Text>
              <Text>DATE: {date}</Text>
            </Box>
          </Box>
        </HStack>
        <Box py={8}>
          <Text fontSize="2xl" fontWeight="bold">
            Your Tax Receipt
          </Text>
          <Text>
            <Text as="span" fontWeight="bold" textTransform="uppercase" mr={3}>
              Receipt ID:
            </Text>
            <Text as="span">{teddyId}</Text>
          </Text>
          <Text>
            <Text fontWeight="bold" textTransform="uppercase" mr={3}>
              Transaction ID:
            </Text>
            <Link
              href={txLink}
              rel="noopener noreferrer nofollow"
              target="_blank"
              textDecoration="underline"
            >
              {txHash}
            </Link>
          </Text>
          <Text>
            <Text as="span" fontWeight="bold" textTransform="uppercase" mr={3}>
              Item:
            </Text>
            <Text as="span">Online cryptocurrency Donation</Text>
          </Text>
          <Text>
            <Text as="span" fontWeight="bold" textTransform="uppercase" mr={3}>
              Quantity:
            </Text>
            <Text as="span">1</Text>
          </Text>
          <Text py={4}>
            <Text as="span" fontWeight="bold" textTransform="uppercase" mr={3}>
              FOR YOUR TAX PURPOSES:
            </Text>
            <Text as="span">
              Your donation is tax deductible to the extent allowed by law.
              Please save this letter for your tax records as confirmation of
              your donation. If you have any questions, please email
              support@teddydao.org.
            </Text>
          </Text>
        </Box>
      </Box>
    </div>
  );
};

const styles = StyleSheet.create({
  page: {
    paddingTop: `32px`,
    paddingBottom: `32px`,
  },
  thankYou: {
    backgroundColor: colors.brand[500],
    height: `64px`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    color: `white`,
    fontWeight: `bold`,
    marginLeft: `32px`,
    marginRight: `32px`,
    marginBottom: `32px`,
  },
  imageSection: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `center`,
  },
  image: {
    width: `150px`,
  },
  heading: {
    textAlign: `center`,
    fontWeight: `bold`,
    overflowWrap: `break-word`,
    marginBottom: `16px`,
  },
  itemHeading: {
    fontWeight: `bold`,
    marginRight: `8px`,
  },
  itemGroup: {
    display: `flex`,
    flexDirection: `row`,
    marginBottom: `8px`,
  },
});

export const DonationReceipt = React.forwardRef<
  ReactInstance,
  DonationReceiptProps
>((props, ref) => (
  <>
    <DonationReceiptMessage
      imagePng={props.imagePng}
      teddyId={props.teddyId}
      txHash={props.txHash}
      txLink={props.txLink}
      date={props.date}
    />
    <Box px={4}>
      <Button
        as={PDFDownloadLink}
        variant="solid"
        colorScheme="teal"
        _hover={{ color: `white` }}
        document={
          <Document>
            <Page style={styles.page}>
              <View style={styles.thankYou}>
                <PDFText
                  style={{
                    ...styles.heading,
                    marginBottom: 0,
                  }}
                >
                  Thank You!
                </PDFText>
              </View>
              <PDFText style={styles.heading}>
                Donation Receipt for your Teddy DAO NFT Purchase
              </PDFText>
              <View style={styles.imageSection}>
                <Image style={styles.image} src={props.imagePng as any} />
                <View style={{ marginLeft: `32px`, fontSize: `12px` }}>
                  <PDFText>The Teddy DAO Foundation</PDFText>
                  <PDFText>85 Sherman Turnpike</PDFText>
                  <PDFText>Redding, Connecticut</PDFText>
                  <PDFText>United States</PDFText>
                  <PDFText>06896</PDFText>
                  <PDFText>EIN: 92-2229692</PDFText>
                  <PDFText>DATE: {props.date}</PDFText>
                </View>
              </View>
              <View style={{ marginLeft: `32px`, marginRight: `32px` }}>
                <PDFText style={{ fontWeight: `bold`, fontSize: `18px` }}>
                  Your Tax Receipt
                </PDFText>
                <View style={styles.itemGroup}>
                  <PDFText style={styles.itemHeading}>Receipt ID:</PDFText>
                  <PDFText>{props.teddyId}</PDFText>
                </View>
                <View
                  style={{
                    ...styles.itemGroup,
                    flexDirection: `column`,
                  }}
                >
                  <PDFText style={styles.itemHeading}>
                    Transaction Link:
                  </PDFText>
                  <PDFText
                    style={{
                      fontSize: `12px`,
                    }}
                  >
                    <PDFlink src={props.txLink!}>{props.txLink}</PDFlink>
                  </PDFText>
                </View>
                <View
                  style={{
                    ...styles.itemGroup,
                  }}
                >
                  <PDFText style={styles.itemHeading}>Item:</PDFText>
                  <PDFText>Online cryptocurrency Donation</PDFText>
                </View>
                <View style={styles.itemGroup}>
                  <PDFText>Quantity:</PDFText>
                  <PDFText>1</PDFText>
                </View>
                <View>
                  <PDFText style={styles.itemHeading}>
                    FOR YOUR TAX PURPOSES
                  </PDFText>
                  <PDFText
                    style={{
                      fontSize: `14px`,
                    }}
                  >
                    Your donation is tax deductible to the extent allowed by
                    law. Please save this letter for your tax records as
                    confirmation of your donation. If you have any questions,
                    please email support@teddydao.org.
                  </PDFText>
                </View>
              </View>
            </Page>
          </Document>
        }
      >
        Download receipt
      </Button>
    </Box>
  </>
));
