import Section from "../../layout/Section";
import { useQuery, gql } from "@apollo/client";
import {
  Box,
  HStack,
  Image,
  Text,
  Button,
  Stack,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import {
  queryTeddyWinningBid,
  Teddy as TeddySG,
} from "../../wrappers/subgraph";
import { useEthers } from "@usedapp/core";
import { hexlify } from "@ethersproject/bytes";
import { useEffect, useMemo, useState } from "react";
import { svg2png } from "../../utils/svg2png";
import Teddy from "../../components/Teddy";
import { buildSVG, PNGCollectionEncoder } from "@luckyfriday/sdk";
import { getTeddyData, ImageData } from "@luckyfriday/assets";
import { Heading, useDisclosure } from "@chakra-ui/react";

import { DonationReceiptModal } from "../../components/DonationReceiptModal";
import { navbarHeight } from "../../theme/constants";

import { convertNumberIntoPixelPng } from "../../utils/convertNumberIntoPixelPng";
import teddyTitle from "../../assets/teddy-title.png";
import { DownloadIcon } from "@chakra-ui/icons";
import { TbReceiptTax } from "react-icons/tb";
import { getTeddy } from "../../components/StandaloneTeddy";
import { buildEtherscanTxLink } from "../../utils/etherscan";
import { unixTimestampToUTC } from "../../utils/date";

const downloadTeddyPNG = (png: string, filename: string) => {
  const downloadEl = document.createElement("a");
  downloadEl.href = png;
  downloadEl.download = filename || "teddy.png";
  downloadEl.click();
};

const PAGINATED_ACCOUNT_QUERY = gql`
  query GetAccountTeddies($account: String!, $first: Int!, $skip: Int!) {
    account(id: $account) {
      totalDonations
      id
      teddies(first: $first, skip: $skip) {
        id
        seed {
          id
          background
          bodyAccessory
          headAccessory
          body
          face
        }
      }
    }
  }
`;

export const MyTeddies = () => {
  const { account } = useEthers();
  const _account = account ? hexlify(account) : "";
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100; // Adjust as needed

  const { data, loading, error, fetchMore } = useQuery(
    PAGINATED_ACCOUNT_QUERY,
    {
      variables: {
        account: _account,
        first: itemsPerPage,
        skip: (currentPage - 1) * itemsPerPage,
      },
      skip: !_account,
    }
  );

  const loadNextPage = () => {
    fetchMore({
      variables: {
        skip: currentPage * itemsPerPage,
      },
    }).then((fetchMoreResult) => {
      if (fetchMoreResult.data.account.teddies.length > 0) {
        setCurrentPage((prev) => prev + 1);
      }
    });
  };

  const loadPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <Section fullWidth={false}>
      <Box minHeight="calc(100vh - 192px)" pt={navbarHeight} pb={8}>
        <Heading
          sx={{
            fontWeight: "bold",
            marginBottom: "32px",
          }}
          fontSize="5xl"
          as="h1"
          textAlign="center"
        >
          My Teddies
        </Heading>
        <Box
          display="grid"
          gridTemplateColumns="repeat(auto-fit, minmax(150px, 200px))"
          gridGap="8px"
          paddingBottom="32px"
          justifyContent="center"
        >
          {loading && <Text textAlign="center">Loading your teddies...</Text>}
          {error && <Text textAlign="center">Error: {error.message}</Text>}
          {data && data.account?.teddies?.length > 0
            ? data.account.teddies.map((teddy: TeddySG, id: number) => (
                <Box key={id}>
                  <TeddyOwned teddy={teddy} />
                </Box>
              ))
            : !loading && (
                <Text textAlign="center">You don't have a Teddy.</Text>
              )}
        </Box>
        <Flex justifyContent="center" mt={4}>
          <Button
            onClick={loadPreviousPage}
            isDisabled={currentPage === 1}
            mr={2}
          >
            Previous
          </Button>
          <Text alignSelf="center" mx={2}>
            Page {currentPage}
          </Text>
          <Button
            onClick={loadNextPage}
            isDisabled={!data || data.account?.teddies?.length < itemsPerPage}
            ml={2}
          >
            Next
          </Button>
        </Flex>
      </Box>
    </Section>
  );
};

interface TeddyOwnedProps {
  teddy: TeddySG;
}

const TeddyOwned = (props: TeddyOwnedProps) => {
  const { teddy } = props;
  // const _ted = getTeddy(teddy.id, teddy.seed);
  const [png, setPng] = useState<string | null>();
  const [shownTeddyId, setShownTeddyId] = useState<string | undefined>(
    undefined
  );
  const donationModalDisclosure = useDisclosure();
  const { account } = useEthers();
  const winningBidQuery = useQuery(
    queryTeddyWinningBid(teddy.id, account || ""),
    {
      skip: !account && !teddy.id,
    }
  );

  const txHash = winningBidQuery.data?.bids
    ? winningBidQuery.data.bids[0]?.id
    : "";

  const blockTimestamp = winningBidQuery.data?.bids
    ? winningBidQuery.data.bids[0]?.blockTimestamp
    : "";

  const winningDate = unixTimestampToUTC(blockTimestamp);

  const txLink = buildEtherscanTxLink(txHash);

  const teddyImage = useMemo(() => {
    if (teddy.id && teddy.seed) {
      return getTeddy(teddy.id, teddy.seed);
    }
  }, [teddy]);

  const handleReceipt = () => {
    donationModalDisclosure.onOpen();
    setShownTeddyId(teddy.id);
  };

  const { parts, background } = getTeddyData(teddy.seed);
  const encoder = new PNGCollectionEncoder(ImageData.palette);

  const svg = buildSVG(parts, encoder.data.palette, background);

  useEffect(() => {
    const loadPng = async () => {
      setPng(await svg2png(svg, 320, 320));
    };
    loadPng();
  }, [svg]);

  const teddyIdAsNum = teddy.id ? Number(teddy.id?.toString()) : 0;

  const teddyIdArrayOfPngs = convertNumberIntoPixelPng(teddyIdAsNum);

  return (
    <Box>
      <Box
        sx={{
          padding: `16px`,
          bg: `gray.200`,
        }}
        style={{ maxWidth: `300px` }}
      >
        {teddyImage && (
          <Teddy
            imgPath={teddyImage.image}
            alt="teddy"
            // className={classes.teddyImg}
            // wrapperClassName={classes.teddyWrapper}
          />
        )}
      </Box>
      <Box>
        <Stack alignItems="flex-start" justifyContent="space-between">
          <HStack
            spacing={2}
            pt={3}
            alignItems="flex-start"
            justifyContent="center"
          >
            <Text as="span">
              <Image src={teddyTitle} alt="teddy" height="12px" />
            </Text>
            <HStack spacing="2px">
              {teddyIdArrayOfPngs.map((item, id) => {
                console.log(item?.value);
                if (!item) {
                  return null;
                }

                return (
                  <Image
                    key={id}
                    src={item.img}
                    alt={item.value}
                    height="12px"
                    sx={{ margin: 0 }}
                  />
                );
              })}
            </HStack>
          </HStack>
          <HStack pb={3}>
            <IconButton
              aria-label="Download PFP"
              variant="outline"
              colorScheme="green"
              size="sm"
              icon={<DownloadIcon />}
              onClick={async () => {
                // setTeddyIdToDisplay(teddy.id);
                // setDisplayTeddy(true);
                console.log("do this download");

                png && downloadTeddyPNG(png, `teddy_#${teddy.id}.png`);
              }}
            />
            <IconButton
              aria-label="Download Receipt"
              variant="outline"
              colorScheme="green"
              width="100%"
              size="sm"
              icon={<TbReceiptTax />}
              onClick={handleReceipt}
            />
          </HStack>
        </Stack>
      </Box>

      {donationModalDisclosure.isOpen && (
        <DonationReceiptModal
          onClose={donationModalDisclosure.onClose}
          isOpen={donationModalDisclosure.isOpen}
          imagePng={png}
          teddyId={shownTeddyId}
          txLink={txLink}
          txHash={txHash}
          date={winningDate}
        />
      )}
    </Box>
  );
};
